@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined");


/* BLUE PROGRESS BAR
How to use: Put in a div with a class name : tv-determinate-progress-bar-blue
*/
.tv-determinate-progress-bar-blue {
  margin-top: 10px;
}

.tv-determinate-progress-bar-blue .mat-progress-bar-fill::after {
  background-color: $tv-accent-02-blue;
}

.tv-determinate-progress-bar-blue .mat-progress-bar-buffer {
  background: $tv-blueish-grey-shade-6;
}

/* GREEN PROGRESS BAR
How to use: Put in a div with a class name : tv-determinate-progress-bar-green
*/
.tv-determinate-progress-bar-green {
  margin-top: $tv-s-margin;
  margin-right: $tv-m-margin;
  border-radius: $tv-s-border-radius;
}

.tv-determinate-progress-bar-green .mat-progress-bar-fill::after {
  background-color: $tv-success-green;
}

.tv-determinate-progress-bar-green .mat-progress-bar-buffer {
  background: $tv-blueish-grey-shade-1;
}

/* CUSTOM DIV THAT CONTAINS INPUT TIMER
How to use: Put in a div with a class name : tv-container-hour-input
*/
.tv-container-hour-input {
  padding: $tv-m-padding;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    border: none !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    padding-left: $tv-xs-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-5;
    width: -webkit-fill-available;
    justify-items: center;
  }
}

/* CUSTOM DIV FOR INPUT SETTING
How to use: Put in a div with a class name : tv-container-setting-input
*/
.tv-container-setting-input {
  padding: $tv-m-padding;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    padding-left: $tv-xs-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
    padding-left: $tv-xs-padding;
    width: -webkit-fill-available;
  }
}

/* CUSTOM DIV THAT CONTAINS INPUT SCREEN TYPE
How to use: Put in a div with a class name : tv-container-hour-input
*/
.tv-container-screen-types-input {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    padding-left: $tv-s-padding;
  }

  .mat-select-placeholder {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
    padding-left: $tv-s-padding;
    width: -webkit-fill-available;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4 !important;
  }
}

/* MAT FORM FIELD NEW SCREEN INFORMATION
How to use: add class tv-new-screen-information-input on mat-form-field
*/
.tv-new-screen-information-input {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
    width: 190px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

.tv-input-disabled {
  .mat-form-field-wrapper {
    background: $tv-blueish-grey-shade-4-transparent-5;
  }
}

/* MAT FORM FIELD NEW SCREEN COMMENT
How to use: add class tv-new-screen-comment-input on mat-form-field
*/
.tv-new-screen-comment-input {
  padding: 10px 0px 10px 0px;
  width: 100%;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 0px 10px 8px 10px;
    width: 415px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

.tv-new-screen-hour-diff-input {
  width: 30px;

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

/*
TV TABLE INPUT RED
How to use: add class tv-table-input-red on mat-form-field
*/
.tv-table-input-red.mat-form-field {
  input {
    caret-color: $tv-blueish-grey-shade-1;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: $tv-accent-01-red;
    -webkit-text-stroke-color: $tv-accent-01-red;
  }

  .mat-form-field-wrapper {
    padding: 0 5px;
    border: 1px solid $tv-accent-01-red;
    border-radius: $tv-xs-border-radius;
  }

  .mat-form-field-infix {
    border: none;
    width: auto;
  }

  .mat-input-element {
    color: $tv-accent-01-red !important;
    font: $tv-text-m-regular;
    margin-bottom: -1px;
  }

  .mat-form-field-label {
    color: $tv-accent-01-red !important;
    font: $tv-text-m-regular;
  }

  .mat-form-field-underline {
    display: none;
  }
}

/*
TV TABLE RED INPUT PLACEHOLDER
How to use: add class tv-table-input-red-placeholder on <mat-placeholder>
*/
.tv-table-input-red-placeholder {
  color: $tv-accent-01-red;
  font: $tv-text-m-regular;
}

/*
TV TABLE RED INPUT PLACEHOLDER SMALL
How to use: add class tv-table-input-red-placeholder-small on <mat-placeholder>
*/
.tv-table-input-red-placeholder-small {
  color: $tv-accent-01-red;
  font: $tv-text-s-regular;
}

/*
TV TABLE GREY INPUT PLACEHOLDER
How to use: add class tv-table-input-grey-placeholder on <mat-placeholder>
*/
.tv-table-input-grey-placeholder {
  color: $tv-blueish-grey-shade-6;
  font: $tv-text-m-regular;
}

/*
TV TABLE INPUT BLACK
How to use: add class tv-table-input-black on mat-form-field
*/

.tv-table-input-black.mat-form-field {
  input {
    caret-color: $tv-blueish-grey-shade-1;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: $tv-blueish-grey-shade-1;
    -webkit-text-stroke-color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-infix {
    border: none;
    width: auto;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1-transparent-80 !important;
    font: $tv-text-m-regular;
    margin-bottom: -1px;
    padding: 6px;
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
  }

  .mat-input-element:disabled {
    border: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    font: $tv-text-m-regular;
  }

  .mat-form-field-underline {
    display: none;
  }
}

/*
TV INPUT TIMER
How to use: add class tv-input-timer on mat-form-field
*/
.tv-input-timer.mat-form-field {
  width: 80px;

  input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

/*
TV DATE RANGE PICKER
How to use: add class tv-date-range-picker on mat-form-field
*/
.tv-date-range-picker.mat-form-field {
  input {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-date-range-input-separator {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-form-field-wrapper {
    height: 32px;
  }

  .mat-datepicker-toggle {
    color: #0083FF;
    padding: 0 10px 5px 5px;
  }

  .mat-datepicker-toggle-default-icon {
    margin: 0 0 2px 4px;
  }
}

/*
TV FILTER DIALOG
How to use: add class tv-filter-dialog on mat-form-field
*/
.tv-filter-dialog.mat-form-field {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    width: auto;
  }

  .mat-select-panel {
    margin-top: $tv-m-margin;
  }

  .mat-select-value, .mat-primary {
    color: $tv-blueish-grey-shade-4 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: $tv-s-margin;
  }

  .mat-select-arrow-wrapper {
    padding-right: 10px;
  }

  .mat-form-field.mat-focused.mat-primary, .mat-select-arrow {
    color: $tv-blueish-grey-shade-4 !important;
  }
}

/*
TV NEW SCREEN DIALOG
How to use: add class tv-new-screen-dialog on mat-form-field
*/
.tv-new-screen-dialog.mat-form-field {
  height: 20px;

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    width: auto;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

/*
Mat Black Spinner
How to use: add class black-spinner on mat-spinner
*/
.mat-progress-spinner.dark-spinner circle, .mat-spinner.dark-spinner circle {
  stroke: $tv-blueish-grey-shade-1;
}

/*
Mat Light Spinner
How to use: add class light-spinner on mat-spinner
*/
.mat-progress-spinner.light-spinner circle, .mat-spinner.light-spinner circle {
  stroke: $tv-blueish-grey-shade-5;
}

/*
Mat Table Cell Center Align
How to use: add class header-cell-center-align on th
*/
.header-cell-center-align > .mat-sort-header-container {
  justify-content: center;
  text-align: center;
}

/*
TV NEW STYLE SELECT ROUND
How to use: add class tv-ns-select-white-round on mat-form-field
!!! Should not be used with a floating label !!!!!
*/
.mat-form-field.tv-ns-select-white-round {
  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xxl-border-radius;
    background-color: $tv-white;

    &:hover {
      background-color: $tv-blueish-grey-shade-6;
    }

    padding: 0;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;

    mat-select {
      margin-bottom: 10px;
    }

    padding: 0 10px 0 14px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-5;
  }
}

.mat-form-field.tv-ns-select-white-round.mat-focused {
  .mat-form-field-label {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }
}

.mat-form-field.tv-ns-select-white-round.mat-form-field-disabled {
  .mat-form-field-wrapper {
    background-color: $tv-blueish-grey-shade-6-transparent-30;
    border: none;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-5;
  }
}

/*
TV NEW STYLE SELECT OPTION
How to use: add class tv-ns-select-option on option
*/
.mat-option.tv-ns-select-option {
  color: $tv-blueish-grey-shade-4 !important;
}

.mat-option.tv-ns-select-option.mat-active {
  background: $tv-blueish-grey-shade-6 !important;
  color: $tv-blueish-grey-shade-5 !important;
}

.mat-option.tv-ns-select-option.mat-selected {
  background: $tv-blueish-grey-shade-6 !important;
  color: $tv-blueish-grey-shade-5 !important;
}

.mat-option.tv-ns-select-option:hover {
  background: $tv-blueish-grey-shade-6 !important;
  color: $tv-blueish-grey-shade-5 !important;
}

/*
TV NEW STYLE SELECT OPTION
How to use: add class tv-select-option on option
*/
.mat-option.tv-select-option {
  color: $tv-blueish-grey-shade-5 !important;
}

.mat-option.tv-select-option.mat-active {
  color: $tv-blueish-grey-shade-5 !important;
}


/*
TV NEW STYLE SELECT ROUND WITHOUT INFIX WIDTH AND SMALL HEIGHT
How to use: add class v-ns-select-white-round and small-infix
*/
.mat-form-field.tv-ns-select-white-round.small-infix {
  .mat-form-field-infix {
    width: auto;
    padding: 4px 8px 4px 8px;
    border-top: 0;

    mat-select {
      margin-bottom: 0;
    }
  }
}

/*
TV NEW STYLE SELECT RECT
How to use: add class tv-ns-select-white-rect on mat-form-field
*/
.mat-form-field.tv-ns-select-white-rect {
  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    width: 286px;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 10px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;

    mat-select {
      margin-bottom: 5px;
    }
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }
}

.mat-form-field.tv-ns-select-white-rect.mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }
}

.mat-form-field.tv-ns-select-white-rect.mat-form-field-disabled {
  .mat-form-field-wrapper {
    background-color: $tv-blueish-grey-shade-7;
    border: 1px solid $tv-blueish-grey-shade-7;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-5;
  }
}


/*
TV NEW STYLE SELECT RECT
How to use: add class tv-ns-select-white-rect-width-m on mat-form-field
*/
.mat-form-field.tv-ns-select-white-rect-width-m {
  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    width: 220px;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 10px;
  }

  .mat-form-field-infix {
    font: $tv-text-s-regular;
    color: $tv-blueish-grey-shade-1;

    mat-select {
      margin-bottom: 5px;
    }
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }
}

.mat-form-field.tv-ns-select-white-rect.mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }
}

.mat-form-field.tv-ns-select-white-rect.mat-form-field-disabled {
  .mat-form-field-wrapper {
    background-color: $tv-blueish-grey-shade-7;
    border: 1px solid $tv-blueish-grey-shade-7;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-5;
  }
}

/* ____ NEW COMMENT BODY---- */
.mat-form-field.tv-comment-white-rect-width-m {
  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    width: 100%;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 10px;
  }

  .mat-form-field-infix {
    font: $tv-text-s-regular;
    color: $tv-blueish-grey-shade-1;

    mat-select {
      margin-bottom: 5px;
    }

  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }
}

.mat-form-field.tv-comment-white-rect-width-m.mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }
}

.mat-form-field.tv-comment-white-rect-width-m.mat-form-field-disabled {
  .mat-form-field-wrapper {
    background-color: $tv-blueish-grey-shade-7;
    border: 1px solid $tv-blueish-grey-shade-7;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-5;
  }
}

/* ---- New White Search Without Label Medium---- */
//How to use: add class tv-ns-white-search-medium on mat-form-field
//!!!! Add  floatLabel="never" on MatFormField

.tv-ns-white-search-medium.mat-form-field {
  input {
    caret-color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-wrapper {
    padding: 4px 5px;
    border-radius: $tv-xxl-border-radius;
    border: 1px solid $tv-blueish-grey-shade-6;
    background-color: $tv-white;

    &:hover {
      background-color: $tv-blueish-grey-shade-6;
    }
  }

  .mat-select-value {
    color: $tv-blueish-grey-shade-5; /* Couleur du texte */
  }

  .mat-form-field-infix {
    padding: 0;
    border: 0;
  }

  .mat-form-field-suffix {
    mat-icon {
      margin-bottom: -5px;
      color: $tv-blueish-grey-shade-5;
    }

    button.tv-clear-input-icon-button {
      mat-icon {
        margin-left: 14px;
        margin-right: 5px;
        margin-bottom: -8px;
      }
    }
  }

  .mat-form-field-prefix {
    mat-icon {
      margin-left: 5px;
      margin-bottom: -5px;
      color: $tv-blueish-grey-shade-1;
    }
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
    //font: $tv-text-m-regular;
    margin: 0 14px 1px 10px;
  }

  .mat-date-range-input-inner {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-date-range-input-separator {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    font: $tv-text-m-regular;
    margin-top: -4px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.tv-ns-white-search-medium-squared.mat-form-field.mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

/* To be used in the content and not header. The content is squared and not rounded*/
.tv-ns-white-search-medium-squared.mat-form-field {
  input {
    caret-color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-wrapper {
    display: flex;
    height: 60px;
    padding: 4px 5px;
    border-radius: $tv-xs-border-radius;
    border: 1px solid $tv-blueish-grey-shade-6;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }
  }

  .mat-form-field-flex {
    display: flex;
    align-items: center;
  }

  .mat-form-field-infix {
    padding: 0;
    border: 0;
  }

  .mat-form-field-suffix {
    mat-icon {
      margin-bottom: -5px;
      color: $tv-blueish-grey-shade-5;
    }

    button.tv-clear-input-icon-button {
      mat-icon {
        margin-left: 14px;
        margin-right: 5px;
        margin-bottom: -8px;
      }
    }
  }

  .mat-form-field-prefix {
    mat-icon {
      margin-left: 5px;
      margin-bottom: -5px;
      color: $tv-blueish-grey-shade-5;
    }
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-5;
    font: $tv-text-m-regular;
    margin: 0 14px 1px 10px;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    font: $tv-text-m-regular;
    margin-top: -4px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.tv-ns-white-search-medium.mat-form-field.mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

/* Custom Mat Dialog */
/*
How to use : add when opening dialog
const dialogRef = this.dialog.open(TvConfirmPopupComponent, {
data: confirmationData,
panelClass: 'tv-ns-dialog-container',
});
*/
.tv-ns-dialog-container .mat-dialog-container {
  border-radius: $tv-m-border-radius;

  .mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $tv-blueish-grey-shade-5-transparent-30;
  }
}

.tv-dialog-container .mat-dialog-container {
  border-radius: 25px;
  overflow: auto !important;
  max-height: 100% !important;
  display: block;
  height: auto !important;
}

/* Custom Mat Button Toggle Group */
.tv-ns-blue-button-toggle-group.mat-button-toggle-group {
  box-shadow: none !important;
  background-color: $tv-blueish-grey-shade-6;
  border: none;
  padding: 4px 2px;
  border-radius: $tv-xxl-border-radius;

  .mat-button-toggle-checked .mat-button-toggle-button {
    background-color: $tv-white;
    border: none;
    border-radius: $tv-xxl-border-radius;
    box-shadow: 0 1px 3px $tv-blueish-grey-shade-5-transparent-30 !important;
  }

  .mat-button-toggle {
    margin: 0 4px;
    background: $tv-blueish-grey-shade-6;
    border-left: none;
    border-radius: $tv-xxl-border-radius;

    &:hover {
      background-color: $tv-white;
      box-shadow: 0 1px 3px $tv-blueish-grey-shade-5-transparent-30 !important;
    }
  }

  .mat-button-toggle-label-content {
    line-height: normal;
    padding: 4px 12px;
    display: flex;
    align-items: center;
  }

  .mat-button-toggle-focus-overlay {
    display: none;
  }

  .mat-ripple {
    display: none;
  }
}

/* Custom Mat Checkbox Blue */
/*
How to use : add on mat-checkbox class tv-ns-blue-checkbox
*/
.tv-ns-blue-checkbox.mat-checkbox-checked.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $tv-accent-02-blue;
  }
}

.tv-ns-blue-checkbox.mat-checkbox {
  color: $tv-blueish-grey-shade-5;
  font: $tv-text-s-regular;
  padding: 5px;

  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-frame {
    border-color: $tv-accent-02-blue;
  }
}

/* Custom Mat Checkbox Blueish gray */
/*
How to use : add on mat-checkbox class tv-ns-blue-checkbox
*/
.tv-blueish-grey-checkbox.mat-checkbox-checked.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $tv-accent-02-blue;
  }
}

.tv-blueish-grey-checkbox.mat-checkbox {
  color: $tv-blueish-grey-shade-5;
  font: $tv-text-s-regular;
  padding: 5px;

  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-frame {
    border-color: $tv-blueish-grey-shade-5;
  }
}

/* Custom Mat Checkbox blue size M */
/*
How to use : add on mat-checkbox class tv-ns-blue-checkbox
*/
.tv-ns-blue-checkbox-m.mat-checkbox-checked.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $tv-accent-02-blue;
  }
}

.tv-ns-blue-checkbox-m.mat-checkbox {
  color: $tv-blueish-grey-shade-5;
  font: $tv-text-m-regular;
  padding: 5px;

  .mat-checkbox-ripple .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-frame {
    border-color: $tv-accent-02-blue;
  }
}

/* Custom Mat SLide Toggle */
/*
How to use : add on tv-mat-slide-toggle class mat-slide-toggle
*/
.tv-mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $tv-success-green !important;
}

.tv-mat-slide-toggle .mat-slide-toggle-bar {
  background-color: $tv-blueish-grey-shade-5-transparent-30 !important;
  width: 34px;
  height: 18px;
  border-radius: 10px;

  .mat-slide-toggle-thumb {
    background-color: $tv-white !important;
    height: 14px;
    width: 14px;
  }

  .mat-slide-toggle-thumb-container {
    width: 14px;
    height: 14px;
    top: 2px;
    left: 2px;

    .mat-ripple {
      display: none;
    }
  }
}

.not-available {
  &:hover {
    background-color: $tv-error-red-transparent-10;
  }
}

.available {
  &:hover {
    background-color: $tv-success-green-transparent-10;
  }
}

/* Custom Mat Chip List */
/*
How to use : add on tv-container-screen-chip-list class mat-chip-list
*/
.tv-container-screen-chip-list.mat-chip-list {
  padding-right: $tv-xs-padding;
  width: max-content;

  .mat-chip.mat-standard-chip {
    background-color: $tv-blueish-grey-shade-6;
    min-height: 25px;
    color: $tv-blueish-grey-shade-5;
  }

  .mat-chip.mat-standard-chip::after {
    background: $tv-white;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: $tv-white;
    color: $tv-blueish-grey-shade-4;
  }
}

.tv-container-forcable-chip-list.mat-chip-list {
  padding-right: $tv-xs-padding;
  width: max-content;

  .mat-chip.mat-standard-chip {
    background-color: $tv-white;
    min-height: 25px;
    color: $tv-blueish-grey-shade-5;
  }

  .mat-chip.mat-standard-chip::after {
    background: $tv-white;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: $tv-success-green;
    color: $tv-white;
  }
}

/* Custom Mat Chip List */
/*
How to use : add on tv-container-screen-forcable-chip-list class mat-chip-list
*/
.tv-container-screen-forcable-chip-list.mat-chip-list {

  .mat-chip.mat-standard-chip {
    background: $tv-blueish-grey-3-tint-3;
    color: $tv-blueish-grey-shade-5;
    padding: 29px 16px;
    border-radius: $tv-xs-border-radius;
    width: 50%;
  }

  .mat-chip.mat-standard-chip::after {
    background: $tv-white;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: $tv-white;
    color: $tv-blueish-grey-shade-4;
  }

  .mat-chip-list-wrapper {
    flex-wrap: nowrap;
  }

  .mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip {
    margin: 0;
  }
}

/* Custom Mat Chip List */
/*
How to use : add tv-container-screen-info-chip-list class on mat-chip-list
*/
.tv-container-screen-info-chip-list.mat-chip-list {
  .mat-chip {
    font-size: 14px;
  }

  .mat-chip.mat-standard-chip {
    background-color: $tv-blueish-grey-shade-3;
  }

  .mat-chip.mat-standard-chip::after {
    background: $tv-white;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: $tv-white;
  }

  .mat-chip-list-wrapper {
    flex-wrap: nowrap;
  }
}

/* Custom Mat Chip List */
/*
How to use : add tv-container-filter-chip class  on mat-chip-list
*/
.tv-container-filter-chip.mat-chip-list {

  .mat-chip.mat-standard-chip {
    color: white;
    text-align: center;
    font-size: 13px;
    margin-bottom: 10px;
    border-radius: 6px;
    background: $tv-blueish-grey-shade-5;
  }

  .mat-chip-list-wrapper {
    padding-right: $tv-xs-padding;
  }
}

/* Custom Mat Chip List */
/*
How to use : add tv-container-screen-type-chip class on mat-chip-list
*/
.tv-container-screen-type-chip.mat-chip-list {

  .mat-chip-list {
    padding-bottom: 5px;
  }

  .mat-chip.mat-standard-chip {
    color: white;
    text-align: center;
    font-size: 11px;
    border-radius: 6px;
    background: $tv-blueish-grey-shade-5;
  }
}

/* Custom Mat Menu */
/*
How to use : add on mat-menu class tv-blue-mat-menu
*/
.tv-blue-mat-menu {
  background: $tv-blueish-grey-shade-1;
}

/* CUSTOM DIV THAT CONTAINS TV NEW STYLE SELECT RECT - FIXED WIDTH 220px
How to use: Put in a div with a class name : tv-container-select-white-rect
*/
.tv-container-select-white-rect {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
   width: 220px;
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    padding-left: $tv-s-padding;
  }

  .mat-select-placeholder {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
    padding-left: $tv-s-padding;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4 !important;
  }
}

//------------------
//------------------
//------------------

/* MAT FORM FIELD NEW INPUT
How to use: add class tv-new-information-input-small on mat-form-field
*/
.tv-new-information-input-small {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
    width: 200px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

/* MAT FORM FIELD NEW INPUT
How to use: add class tv-new-information-input-medium on mat-form-field
*/
.tv-new-information-input-medium {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
    width: 250px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

/* MAT FORM FIELD NEW INPUT
How to use: add class tv-new-information-input-large on mat-form-field
*/
.tv-new-information-input-large {
  padding: 10px 0 10px 0;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
    width: 350px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}

.tv-ns-white-search-medium .mat-form-field .mat-focused {
  .mat-form-field-wrapper {
    border: 1px solid $tv-accent-02-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 131, 255, 0.3);
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}


/* MAT FORM FIELD NEW INPUT
How to use: add class tv-new-information-input-small-with-rate-value on mat-form-field
*/
.tv-new-information-input-small-with-rate-value {
  padding: 10px 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0px;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
    width: 200px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5;
    padding-left: $tv-s-padding;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
  }
}
